import {
  Box,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { useState, useEffect } from "react";
import axios from "axios";
import dotenv from "react-dotenv";
import { useTranslation } from "react-i18next";

const SelectPathologyAndMedicalGroup = (props) => {
  const [pathologies, setPathologies] = useState([]);
  const [medicalGroups, setMedicalGroups] = useState([]);
  const { user } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    getUserPathologies();
    getUserPreferences();
  }, []);

  useEffect(() => {
    if (props.onlyPathology != null) {
      props.setSelectedPathology(pathologies.find(p => p.name == props.onlyPathology)?.id)
    }
  }, [pathologies]);

  useEffect(() => {
    const isSelectedMedicalGroupInGroups = medicalGroups.some(
      (group) => group.id == props.selectedMedicalGroup
    );
    if (isSelectedMedicalGroupInGroups) {
      props.updateTable(props.selectedPathology, props.selectedMedicalGroup);
    }
  }, [props.selectedMedicalGroup]);

  useEffect(() => {
    if (!props.selectedPathology) {
      getUserPreferences();
    }
    if (props.selectedMedicalGroup == 0) {
      props.updateTable(props.selectedPathology, props.selectedMedicalGroup);
    }
  }, [props.selectedPathology]);
  useEffect(() => {
    if (user) {
      getUserPreferences();
    }
  }, [user, props.endpoint]);

  useEffect(() => {
    const isSelectedMedicalGroupInGroups = medicalGroups.some(
      (group) => group.id == props.selectedMedicalGroup
    );
    if (!isSelectedMedicalGroupInGroups) {
      props.setSelectedMedicalGroup(0);
    }
  }, [medicalGroups]);

  const handlePathologyChange = (event) => {
    if (props.onlyPathology == null) {
      props.setSelectedPathology(event.target.value);
      setPreferencePathology(props.preferencePathology, event.target.value);
      getMedicalGroups(event.target.value);
    }
  };

  const handleMedicalGroupChange = (event) => {
    props.setSelectedMedicalGroup(event.target.value);
    setPreferencePathology(props.preferenceMedicalGroup, event.target.value);
  };

  const getUserPreferences = () => {
    const fetchData = async () => {
      const url = new URL(
        `/institutions/v1/users/${user.user.id}/preferences/${props.endpoint}`,
        dotenv.API_URL
      );

      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          if (props.onlyPathology == null) {
            props.setSelectedPathology(
              response.data[props.preferencePathology] ?? 1
            );
          }
          props.setSelectedMedicalGroup(
            response.data[props.preferenceMedicalGroup] ?? 0
          );
          if (props.onlyPathology == null) {
            getMedicalGroups(response.data[props.preferencePathology] ?? 1);
          } else {
            getMedicalGroups(pathologies.find(p => p.name == props.onlyPathology)?.id);
          }
        });
    };
    fetchData();
  };

  const getUserPathologies = () => {
    const fetchData = async () => {
      const url = new URL(
        "/institutions/v1/users/get-user-pathologies",
        dotenv.API_URL
      );
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          setPathologies(response.data.pathologies);
        });
    };
    fetchData();
  };

  const getMedicalGroups = (pathology_id) => {
    const fetchData = async () => {
      const url = new URL(
        "/institutions/v1/medical-groups/by-pathology/" + pathology_id,
        dotenv.API_URL
      );
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          let mGroups = [{ id: 0, name: "TODOS" }];
          mGroups.push(response.data["medical-groups"]);
          mGroups = mGroups.flat(1);
          setMedicalGroups(mGroups);
        });
    };
    fetchData();
  };

  const setPreferencePathology = (key, value) => {
    const fetchData = async () => {
      const url = new URL(
        "/institutions/v1/users/set-user-preference",
        dotenv.API_URL
      );
      axios.post(
        url.href,
        { key: key, value: value },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      );
    };
    fetchData();
  };

  const select = {
    backgroundColor: "#FFF",
    borderRadius: "8px",
    border: "1px solid #869AE8",
    color: "#869AE8",
    height: "55px",
    minWidth: "190px",
  };

  return (
    <>
      {pathologies.length > 0 && medicalGroups.length > 0 ? (
        <>
          <Box>
            <InputLabel>{t("filter_by")}: </InputLabel>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box marginLeft={"10px"}>
                <FormHelperText>{t("patology")}</FormHelperText>
                <Select
                  disabled={props.onlyPathology != null}
                  displayEmpty
                  sx={select}
                  id="patology-input"
                  name="selectedPathology"
                  value={props.selectedPathology}
                  onChange={handlePathologyChange}
                >
                  {pathologies.map((pathology) => (
                    <MenuItem value={pathology.id} key={pathology.id}>
                      {pathology.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box marginLeft={"10px"}>
                <FormHelperText>{t("medicalGroup")}</FormHelperText>
                <Select
                  displayEmpty
                  label="Equipo médico"
                  sx={select}
                  id="medical-group-input"
                  name="selectedMedicalGroup"
                  value={props.selectedMedicalGroup}
                  onChange={handleMedicalGroupChange}
                >
                  {medicalGroups.map((mg) => (
                    <MenuItem value={mg.id} key={mg.id}>
                      {mg.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default SelectPathologyAndMedicalGroup;
