import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import dotenv from "react-dotenv";
import { Box, Button, InputLabel, Select, MenuItem } from "@mui/material";
import ProfileData from "../../components/ProfileData";
import moment from "moment";
import MedicalCoverageData from "../../components/MedicalCoverageData";
import EulaData from "../../components/EulaData";
import HealthData from "../../components/HealthData";
import HTACharts from "./components/HTACharts";
import ICCCharts from "./components/ICCCharts";

import IosShareIcon from "@mui/icons-material/IosShare";
import PatientButtons from "../../components/PatientButtons";
import MedicationPlanData from "./components/MedicationPlanData";
import HealthBackground from "../../components/HealthBackground";
import HTAModality from "../../components/HTAModality";
import ECGHistory from "./components/ECGHistory";
import { useTranslation } from "react-i18next";
import SelectModality from "./components/SelectModality";

const SpecificPatientIndexPage = (props) => {
  const { user } = useAuth();
  const [patientData, setPatientData] = useState(null);
  const [medicationPlans, setMedicationPlans] = useState([]);
  const [eulaData, setEula] = useState([]);
  const [selectedModality, setSelectedModality] = useState(1);
  const [chartImages, setChartImages] = useState({});
  const [count, setCount] = useState("10");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [creatingPDF, setCreatingPDF] = useState(false);
  const { id } = useParams();
  const { t } = useTranslation();

  const exportToPDF = async () => {
    setCreatingPDF(true);
    const url = new URL(
      `/institutions/v1/patients/${id}/export-pdf`,
      dotenv.API_URL
    );
    axios
      .post(url.href, chartImages, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        const blobUrl = URL.createObjectURL(
          new Blob([response.data], { type: "pdf" })
        );
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", "historial.pdf");
        link.click();
      })
      .catch((error) => {
        setCreatingPDF(false);
      })
      .finally(() => {
        setCreatingPDF(false);
      });
  };

  useEffect(() => {
    getPatientData();
    getMedicationPlans();
  }, []);

  const getPatientData = () => {
    const fetchData = async () => {
      const url = new URL(
        `/institutions/v1/patients/${id}/resume`,
        dotenv.API_URL
      );
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          if (response.data) {
            const patientDataWithId = {
              id: id,
              ...response.data,
            };
            setPatientData(patientDataWithId);
            props.setTitle(
              ["Pacientes"],
              response.data.profile_data?.name +
                " " +
                response.data.profile_data?.lastname
            );
          }
        })
        .catch()
        .finally();
    };
    fetchData();
  };

  const getMedicationPlans = () => {
    const fetchData = async () => {
      const url = new URL(
        `/institutions/v1/patients/${id}/medication-plans/active`,
        dotenv.API_URL
      );
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          if (response.data) {
            setMedicationPlans(response.data.data);
          }
        })
        .catch()
        .finally();
    };
    fetchData();
  };

  const getEula = () => {
    const fetchData = async () => {
      const url = new URL(
        `/institutions/v1/patients/${id}/eula`,
        dotenv.API_URL
      );
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          if (response.data) {
            setEula(response.data);
          }
        })
        .catch()
        .finally();
    };
    fetchData();
  };

  useEffect(() => {
    getEula();
  }, []);

  const saveChartImage = (image, slug) => {
    setChartImages((prevState) => ({ ...prevState, [slug]: image }));
  };

  const styles = {
    patientName: {
      color: "#3657D9",
      fontSize: "24px",
      lineHeight: "36px",
      fontWeight: "600",
    },
    backgroundBox: {
      width: "100%",
      padding: "20px 30px",
      backgroundColor: "#FFFFFF",
      borderRadius: "18px",
      margin: "10px 0px",
    },
    enrollDate: {
      color: "#869AE8",
      fontSize: "14px",
      lineHeight: "21px",
      fontWeight: "400",
    },
    button: {
      marginTop: "20px",
      width: "300px",
      borderRadius: "15px",
      background: !creatingPDF ? "#3657D9" : "#f4f5f8",
      color: !creatingPDF ? "white" : "black",
      marginBottom: "25px",
    },
    boxContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    inputLabelContainer: {
      display: "flex",
      flexDirection: "column",
    },
    label: {
      fontSize: "14px",
      color: "#869AE8",
      lineHeight: "21px",
    },
    inputFields: {
      borderRadius: "12px",
      border: "1px solid #869AE8",
      padding: "0 20px",
      fontFamily: "Poppins",
      color: "#869AE8",
      fontSize: "14px",
      height: "41px",
      minWidth: "170px",
      width: "100%",
      marginTop: "5px",
    },
  };

  return (
    <>
      {patientData ? (
        <Box>
          <Box sx={styles.backgroundBox}>
            <Box sx={styles.boxContainer}>
              <div sx={styles.inputLabelContainer}>
                <InputLabel sx={styles.patientName}>
                  {patientData?.profile_data?.name +
                    " " +
                    patientData?.profile_data?.lastname}
                </InputLabel>
                <InputLabel sx={styles.enrollDate}>
                  {t("date_enrollment")}:
                  {" " +
                    moment(patientData?.profile_data?.enroll_date).format(
                      "DD/MM/yyyy"
                    )}
                </InputLabel>
                <InputLabel
                  sx={[
                    styles.enrollDate,
                    { fontWeight: "600", marginBottom: "24px" },
                  ]}
                >
                  {t("patology")}: {patientData?.pathology?.name}
                </InputLabel>
              </div>
              <Button
                disabled={creatingPDF}
                sx={styles.button}
                onClick={exportToPDF}
              >
                {!creatingPDF ? t("export") : t("generating_pdf")}
                {!creatingPDF ? <IosShareIcon /> : <></>}
              </Button>
            </Box>
            <ProfileData
              id={patientData?.id}
              weight={patientData?.health_data?.weight}
              pathology={patientData?.pathology?.name}
              profileData={patientData?.profile_data}
              summary={patientData?.collaborative_summary}
            ></ProfileData>
            <EulaData data={eulaData}></EulaData>
            <MedicalCoverageData
              medicalCoverageData={patientData?.medical_coverage_data}
            ></MedicalCoverageData>
            <HealthData
              disnea={patientData?.disnea}
              healthData={patientData?.health_data}
            ></HealthData>
            {patientData?.pathology?.name == "HTA" ? (
              <>
                <HealthBackground
                  healthBackground={patientData?.health_background}
                />
                <HTAModality modality={patientData?.modality} />
              </>
            ) : (
              <></>
            )}
          </Box>
          <PatientButtons
            pathology={patientData?.pathology?.name.toLowerCase()}
            name={
              patientData?.profile_data?.name +
              " " +
              patientData?.profile_data?.lastname
            }
            role={props.role}
          ></PatientButtons>
          <Box sx={styles.backgroundBox}>
            <MedicationPlanData
              name={
                patientData?.profile_data?.name +
                " " +
                patientData?.profile_data?.lastname
              }
              getMedicationPlans={getMedicationPlans}
              medicationPlans={medicationPlans}
              vademecum={patientData?.vademecum}
              patientId={id}
            ></MedicationPlanData>
          </Box>
          <Box
            sx={[
              styles.backgroundBox,
              {
                display: "flex",
                alignItems: "center",
                gap: "10%",
                justifyContent: "center",
                backgroundColor: "#EFF0F8",
              },
            ]}
          >
            <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <Box>
                <InputLabel sx={styles.label}>{t("date_from")}</InputLabel>
                <input
                  type="date"
                  max={endDate ?? moment().toISOString().split("T")[0]}
                  onChange={(evt) => setStartDate(evt.target.value)}
                  style={styles.inputFields}
                />
              </Box>
              <Box>
                <InputLabel sx={styles.label}>{t("date_until")}</InputLabel>
                <input
                  type="date"
                  max={moment().toISOString().split("T")[0]}
                  min={startDate ?? undefined}
                  onChange={(evt) => setEndDate(evt.target.value)}
                  style={styles.inputFields}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <InputLabel sx={styles.label}>{t("show_latest")}</InputLabel>
              <Select
                sx={{
                  margin: "0 10px",
                  borderRadius: "7.38px",
                  height: "24px",
                  fontSize: "11px",
                  color: "#869AE8",
                }}
                value={count}
                onChange={(evt) => setCount(evt.target.value)}
                label="Cantidad de datos"
              >
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="20">20</MenuItem>
                <MenuItem value="50">50</MenuItem>
                <MenuItem value="100">100</MenuItem>
              </Select>
              <InputLabel sx={styles.label}>{t("results")}</InputLabel>
            </Box>
          </Box>
          {patientData?.pathology?.name == "ICC" ? (
            <ICCCharts
              id={id}
              count={count}
              startDate={startDate}
              endDate={endDate}
              saveChartImage={saveChartImage}
            />
          ) : (
            <Box>
              <SelectModality
                modality={selectedModality}
                selectedModality={setSelectedModality}
              />
              <HTACharts
                id={id}
                count={count}
                startDate={startDate}
                endDate={endDate}
                modality={selectedModality}
                saveChartImage={saveChartImage}
              />
            </Box>
          )}
          <Box>
            {patientData?.pathology?.name == "ICC" ? (
              <ECGHistory patient={id} />
            ) : (
              <></>
            )}
          </Box>
          <PatientButtons
            pathology={patientData?.pathology?.name.toLowerCase()}
            name={
              patientData?.profile_data?.name +
              " " +
              patientData?.profile_data?.lastname
            }
            role={props.role}
          ></PatientButtons>
        </Box>
      ) : (
        <InputLabel sx={[styles.patientName, { textAlign: "center" }]}>
          {t("loading")}
        </InputLabel>
      )}
      <br />
    </>
  );
};

export default SpecificPatientIndexPage;
