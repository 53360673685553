import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import dotenv from "react-dotenv";
import axios from "axios";
import { useTranslation } from "react-i18next";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  const value = useMemo(() => {
    const login = async (data, navigateTo) => {
      setUser(data);
      navigate(navigateTo);
    };

    const logout = () => {
      setUser(null);
      navigate("/login");
    };

    return {
      user,
      login,
      logout,
    };
  }, [user, navigate, setUser]);

  const unguardPaths = ["/login"];

  useEffect(() => {
    if (user?.token && !unguardPaths.includes(location.pathname)) {
      const url = new URL("/institutions/v1/user/me", dotenv.API_URL);
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          setUser({ ...user, user: { ...response.data } });
        })
        .catch(() => {
          setUser(null);
          navigate("/login");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [navigate]);

  return (
    <AuthContext.Provider value={value}>
      {loading ? (
        <center>
          <h2>{t("loading")}</h2>
        </center>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
