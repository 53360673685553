import { useState, useEffect } from "react";
import SelectPathologyAndMedicalGroup from "../../components/SelectPathologyAndMedicalGroup";
import axios from "axios";
import dotenv from "react-dotenv";
import { useAuth } from "../../hooks/useAuth";
import ActualMemberships from "./components/ActualMemberships";
import { Box, Button, Grid } from "@mui/material";
import PieChart from "./components/PieChart";
import IosShareIcon from "@mui/icons-material/IosShare";
import moment from "moment";
import BarChartByDay from "./components/BarChartByDay";
import { useTranslation } from "react-i18next";

const StatisticsDashboardPage = (props) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [creatingPDF, setCreatingPDF] = useState(false);
  const [selectedPathology, setSelectedPathology] = useState("");
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedMedicalGroup, setSelectedMedicalGroup] = useState(0);
  const [chartImages, setChartImages] = useState({});
  const [monthDays, setMonthDays] = useState([]);

  useEffect(() => {
    getStatistics();
    getMonthDays();
  }, []);

  const getStatistics = () => {
    const fetchData = async () => {
      if (
        selectedPathology != null &&
        selectedMedicalGroup != null &&
        selectedPathology != ""
      ) {
        setLoading(true);
        const url = new URL(
          "/institutions/v1/memberships/statistics-dashboard",
          dotenv.API_URL
        );
        url.searchParams.set("pathologyId", selectedPathology);
        url.searchParams.set("medicalGroupId", selectedMedicalGroup);
        axios
          .get(url.href, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + user.token,
            },
          })
          .then((response) => {
            setStatistics(response.data);
          })
          .finally(() => setLoading(false));
      }
    };
    fetchData();
  };

  const getMonthDays = () => {
    const daysInMonth = moment().daysInMonth();

    const daysOfMonth = [];

    for (let day = 1; day <= daysInMonth; day++) {
      const date = moment().date(day);
      const formattedDate = date.format("D/M");
      daysOfMonth.push(formattedDate);
    }
    setMonthDays(daysOfMonth);
  };

  const handleExportData = () => {
    let pdfInfo = {
      actual_memberships:
      {
        cards: [
          {
            title: t("new_users"),
            subtitle: t("new_users_month"),
            background: "#3DA6CC",
            color: "#FFF",
            memberships: statistics?.newMemberships
          },
          {
            title: t("active_users"),
            subtitle: t("active_users_month"),
            background: "#5EC360",
            color: "#FFF",
            memberships: statistics?.activeMemberships
          },
          {
            title: t("inactive_users"),
            subtitle: t("inactive_users_month"),
            background: "#FFD952",
            color: "#737589",
            memberships: statistics?.inactiveMemberships
          },
        ],
        chart: chartImages[t("user_activity")],
        title: t("user_activity"),
        subtitle: t("user_activity_subtitle"),
        footer: t("user_activity_footer")
      },
      pie_charts: [
        {
          title: t("user_upload_information"),
          subtitle: t("porc_upload_data"),
          colors: ["#73B771", "#D04444"],
          labels: [t("data_loaded"), t("data_not_loaded")],
          chart: chartImages[t("user_upload_information")]
        },
        {
          title: t("alarms_response"),
          subtitle: t("porcentage_alarms_finished"),
          colors: ["#73B771", "#FFD952", "#D04444"],
          labels: [
            t("resolved_in_twenty"),
            t("resolved_more_twenty"),
            t("resolved_after_twenty"),
          ],
          chart: chartImages[t("alarms_response")]
        },
        {
          title: t("patient_controled"),
          subtitle: t("percentage_measurement_rang"),
          colors: ["#73B771", "#D04444"],
          labels: [t("dont_had_alarms"), t("have_alarms")],
          chart: chartImages[t("patient_controled")],
          footer: t("regular_parameter_footer")
        },
      ],
      alerts_by_priority:
      {
        chart: chartImages[t("alerts_by_priority")],
        title: t("alerts_by_priority"),
        subtitle: t("alerts_by_priority_subtitle"),
        labels: monthDays,
        colors: ["#D04444", "#FFD952"],
        items: selectedPathology == 1
          ? [
            "alerts",
            t("alerts_by_priority_high"),
            t("alerts_by_priority_low"),
          ]
          : ["alerts", t("alerts_by_priority_high")],
      },
      alerts_completed:
      {
        chart: chartImages[t("alerts_completed")],
        title: t("alerts_completed"),
        subtitle: t("alerts_completed_subtitle"),
        labels: monthDays,
        colors: ["#5EC360"],
        items: ["alerts", t("alerts_completed_label")]
      }
    }
    setCreatingPDF(true);
    const url = new URL(
      `/institutions/v1/reports/statistics-report`,
      dotenv.API_URL
    );
    axios
      .post(url.href, pdfInfo, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        const blobUrl = URL.createObjectURL(
          new Blob([response.data], { type: "pdf" })
        );
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", "statistics-dashboard.pdf");
        link.click();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setCreatingPDF(false);
      });
  }

  const saveChartImage = (image, slug) => {
    setChartImages((prevState) => ({ ...prevState, [slug]: image }));
  };

  return (
    <Box>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"end"}>
        <SelectPathologyAndMedicalGroup
          endpoint="statistics-dashboard"
          preferencePathology="statistics-dashboard_default_pathology"
          preferenceMedicalGroup="statistics-dashboard_default_medical_group"
          setSelectedPathology={setSelectedPathology}
          setSelectedMedicalGroup={setSelectedMedicalGroup}
          selectedPathology={selectedPathology}
          selectedMedicalGroup={selectedMedicalGroup}
          updateTable={getStatistics}
        />
        <Button sx={{ display: "flex", maxWidth: "303px", height: "54px", borderRadius: "15px", padding: "12px 26px", background: !creatingPDF? "#3657D9" : "#F4F5F8", color: !creatingPDF? "white" : "black" }}
          onClick={handleExportData}
          disabled={creatingPDF}
          variant="contained">
            {!creatingPDF ? t("export") : t("generating_pdf")}
            {!creatingPDF ? <IosShareIcon /> : <></>}
        </Button>
      </Box>
      {!loading ? (
        <>
          <ActualMemberships
            activeMemberships={statistics?.activeMemberships}
            inactiveMemberships={statistics?.inactiveMemberships}
            newMemberships={statistics?.newMemberships}
            chartImage={saveChartImage}
          />

          <Grid container spacing={1} marginTop={"18px"}>
            <Grid item xs={4}>
              <PieChart
                data={[
                  statistics?.patientsWithMeasurements?.length,
                  statistics?.patientsWithoutMeasurements?.length,
                ]}
                chartImage={saveChartImage}
                title={t("user_upload_information")}
                subtitle={t("porc_upload_data")}
                colors={["#73B771", "#D04444"]}
                labels={[t("data_loaded"), t("data_not_loaded")]}
              />
            </Grid>
            <Grid item xs={4}>
              <PieChart
                data={[
                  statistics?.alerts?.alertsResolvedInOneDay,
                  statistics?.alerts?.alertsResolvedInTwoDays,
                  statistics?.alerts?.alertsResolvedInMoreDays,
                ]}
                chartImage={saveChartImage}
                title={t("alarms_response")}
                subtitle={t("porcentage_alarms_finished")}
                space={<br></br>}
                colors={["#73B771", "#FFD952", "#D04444"]}
                labels={[
                  t("resolved_in_twenty"),
                  t("resolved_more_twenty"),
                  t("resolved_after_twenty"),
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <PieChart
                data={[
                  statistics?.alerts?.patientsWithAlerts,
                  statistics?.alerts?.patientsWithoutAlerts,
                ]}
                chartImage={saveChartImage}
                title={t("patient_controled")}
                subtitle={t("percentage_measurement_rang")}
                colors={["#73B771", "#D04444"]}
                labels={[t("dont_had_alarms"), t("have_alarms")]}
                footer={t("regular_parameter_footer")}
              />
            </Grid>
          </Grid>

          <BarChartByDay
            chartImage={saveChartImage}
            title={t("alerts_by_priority")}
            subtitle={t("alerts_by_priority_subtitle")}
            data={statistics?.alerts?.alertsByDay}
            priority={true}
            pathology={selectedPathology}
            labels={monthDays}
            colors={["#D04444", "#FFD952"]}
            items={
              selectedPathology == 1
                ? [
                  "alerts",
                  t("alerts_by_priority_high"),
                  t("alerts_by_priority_low"),
                ]
                : ["alerts", t("alerts_by_priority_high")]
            }
          />
          <BarChartByDay
            chartImage={saveChartImage}
            title={t("alerts_completed")}
            subtitle={t("alerts_completed_subtitle")}
            data={statistics?.alerts?.alertsByDay}
            priority={false}
            labels={monthDays}
            colors={["#5EC360"]}
            items={["alerts", t("alerts_completed_label")]}
          />
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};
export default StatisticsDashboardPage;
