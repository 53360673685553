import { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Select,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Backdrop,
  CircularProgress
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Grid from "@mui/material/Unstable_Grid2";
import AddressTextField from "../../components/AddressTextField";
import axios from "axios";
import dotenv from "react-dotenv";
import { TextField, SelectSx } from "../../theme/vs-theme";
import WithMessages from "../../components/WithMessages";
import { useTranslation } from "react-i18next";

const CustomerCreatePage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [previewSrc, setPreviewSrc] = useState("");
  const [errors, setErrors] = useState({});
  const [patientData, setPatientData] = useState(null);

  const [form, setForm] = useState({
    avatar: "",
    name: "",
    business_name: "",
    id_type_id: "",
    id_number: "",
    city: "",
    address: "",
    phone: "",
    web: "",
    contact_fullname: "",
    contact_phone: "",
    contact_email: "",
  });
  const { id } = useParams();

  const getCustomerData = () => {
    const fetchData = async () => {
      const url = new URL(`/institutions/v1/customers/${id}`, dotenv.API_URL);
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          if (response.data) {
            setForm({
              ...form,
              name: response.data.name,
              business_name: response.data.business_name,
              business_email: response.data.business_email,
              web: response.data.web,
              institution_id: response.data.institution_id,
              id_number: response.data.id_number,
              id_type_id: response.data.id_type_id,
              address:
                response.data.addresses.length > 0
                  ? response.data.addresses[0].address
                  : null,
              phone:
                response.data.phones.length > 0
                  ? response.data.phones[0].number
                  : null,
              contact_fullname: response.data.contact
                ? response.data.contact.fullname
                : null,
              contact_email: response.data.contact
                ? response.data.contact.email
                : null,
              contact_phone:
                response.data.contact.phones.length > 0
                  ? response.data.contact.phones[0].number
                  : null,
              city:
                response.data.addresses.length > 0
                  ? response.data.addresses[0].city
                  : null,
            });
          }
        })
        .catch()
        .finally();
    };
    fetchData();
  };
  useEffect(() => {
    getCustomerData();
  }, []);

  const handleChange = (event) => {
    if (errors[event.target.name]) {
      setErrors({ ...errors, [event.target.name]: null });
    }
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const setAvatar = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewSrc(reader.result);
      };
      reader.readAsDataURL(file);
      setForm({ ...form, avatar: file });
    }
  };

  const saveCustomer = () => {
    let updatedForm = { ...form };
    setLoading(true);
    setForm(updatedForm);
    const url = new URL("/institutions/v1/customers/" + id, dotenv.API_URL);
    axios
      .put(url.href, updatedForm, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then(() =>
        props.showMessage(t("grouper_was_edit"), () => navigate("/customers"))
      )
      .catch((error) => setErrors(error.response.data?.errors))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {true ? (
        <div>
          <Grid>
            <Avatar
              alt="avatar"
              src={previewSrc}
              sx={{
                width: "80px",
                height: "80px",
                border: 4,
                backgroundColor: "#D9D9D9",
                borderColor: "white",
              }}
            />
          </Grid>
          <Grid xs={2}>
            <label htmlFor="avatar-file">
              <input
                name="avatar"
                onClick={(event) => {
                  event.target.value = null;
                }}
                onInput={setAvatar}
                accept="image/*"
                id="avatar-file"
                type="file"
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                component="span"
                color="primary"
                endIcon={<img src={"/plus.png"} alt={"Avatar"} />}
                sx={{ width: 200, ml: 3 }}
              >
                {t("add_photo")}
              </Button>
            </label>
          </Grid>
          <Grid xs={12} sx={{ mt: 4 }}>
            <Paper sx={{ backgroundColor: "white", padding: 4 }} elevation={0}>
              <Grid container spacing={4}>
                <Grid xs={3}>
                  <FormControl variant="standard">
                    <InputLabel shrink htmlFor="name-input">
                      {t("name")}
                    </InputLabel>
                    <TextField
                      id="name-input"
                      name="name"
                      value={form.name}
                      onChange={handleChange}
                    />
                    <FormHelperText
                      id="name-input-error-text"
                      sx={{ color: "red" }}
                    >
                      {errors.name && errors.name[0]}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid xs={3}>
                  <FormControl variant="standard">
                    <InputLabel shrink htmlFor="business-name-input">
                      {t("business_name")}
                    </InputLabel>
                    <TextField
                      id="business-name-input"
                      name="business_name"
                      value={form.business_name}
                      onChange={handleChange}
                    />
                    <FormHelperText
                      id="business-name-input-error-text"
                      sx={{ color: "red" }}
                    >
                      {errors.business_name && errors.business_name[0]}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid xs={3}>
                  <FormControl variant="outlined">
                    <InputLabel shrink htmlFor="id-type-input" sx={{ mt: 1 }}>
                      {t("type_identification")}
                    </InputLabel>
                    <Select
                      sx={SelectSx}
                      id="id-type-input"
                      name="id_type_id"
                      value={form.id_type_id}
                      onChange={handleChange}
                    >
                      <MenuItem value={5}>CUIT</MenuItem>
                      <MenuItem value={6}>CUIL</MenuItem>
                    </Select>
                    <FormHelperText
                      id="id-type-input-error-text"
                      sx={{ color: "red" }}
                    >
                      {errors.id_type_id && errors.id_type_id[0]}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid xs={3}>
                  <FormControl variant="standard">
                    <InputLabel shrink htmlFor="id-number-input">
                      {t("type_identification")}
                    </InputLabel>
                    <TextField
                      id="id-number-input"
                      name="id_number"
                      value={form.id_number}
                      onChange={handleChange}
                    />
                    <FormHelperText
                      id="id-number-input-error-text"
                      sx={{ color: "red" }}
                    >
                      {errors.id_number && errors.id_number[0]}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={6}>
                  <FormControl variant="standard">
                    <InputLabel shrink htmlFor="address-input">
                      {t("adress")}
                    </InputLabel>
                    <TextField
                      id="address-input"
                      name="address"
                      value={form.address}
                      onChange={handleChange}
                    />
                    <FormHelperText
                      id="address-input-error-text"
                      sx={{ color: "red" }}
                    >
                      {errors.address && errors.address[0]}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid xs={6}>
                  <FormControl variant="standard">
                    <InputLabel shrink htmlFor="city-input">
                      {t("city")}
                    </InputLabel>
                    <AddressTextField
                      id="city-input"
                      name="city"
                      value={form.city}
                      onChange={handleChange}
                    />
                    <FormHelperText
                      id="city-input-error-text"
                      sx={{ color: "red" }}
                    >
                      {errors.city && errors.city[0]}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={5}>
                  <FormControl variant="standard">
                    <InputLabel shrink htmlFor="business-email-input">
                      {t("email")}
                    </InputLabel>
                    <TextField
                      id="business-email-input"
                      name="business_email"
                      value={form.business_email}
                      onChange={handleChange}
                    />
                    <FormHelperText
                      id="business-email-input-error-text"
                      sx={{ color: "red" }}
                    >
                      {errors.business_email && errors.business_email[0]}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid xs={3}>
                  <FormControl variant="standard">
                    <InputLabel shrink htmlFor="phone-input">
                      {t("phone")} {t("optional")}
                    </InputLabel>
                    <TextField
                      id="phone-input"
                      name="phone"
                      value={form.phone}
                      onChange={handleChange}
                    />
                    <FormHelperText
                      id="phone-input-error-text"
                      sx={{ color: "red" }}
                    >
                      {errors.phone && errors.phone[0]}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid xs={4}>
                  <FormControl variant="standard">
                    <InputLabel shrink htmlFor="web-input">
                      {t("website")}
                    </InputLabel>
                    <TextField
                      id="web-input"
                      name="web"
                      value={form.web}
                      onChange={handleChange}
                    />
                    {errors.web && (
                      <FormHelperText
                        id="web-input-error-text"
                        sx={{ color: "red" }}
                      >
                        {t("format_must_be")}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={4}>
                  <FormControl variant="standard">
                    <InputLabel shrink htmlFor="contact-fullname-input">
                      {t("reference_name")}
                    </InputLabel>
                    <TextField
                      id="contact-fullname-input"
                      name="contact_fullname"
                      value={form.contact_fullname}
                      onChange={handleChange}
                    />
                    <FormHelperText
                      id="contact-fullname-input-error-text"
                      sx={{ color: "red" }}
                    >
                      {errors.contact_fullname && errors.contact_fullname[0]}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid xs={4}>
                  <FormControl variant="standard">
                    <InputLabel shrink htmlFor="contact-email-input">
                      {t("reference_email")}
                    </InputLabel>
                    <TextField
                      id="contact-email  -input"
                      name="contact_email"
                      value={form.contact_email}
                      onChange={handleChange}
                    />
                    <FormHelperText
                      id="contact-email-input-error-text"
                      sx={{ color: "red" }}
                    >
                      {errors.contact_email && errors.contact_email[0]}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid xs={4}>
                  <FormControl variant="standard">
                    <InputLabel shrink htmlFor="contact-phone-input">
                      {t("reference_phone")}
                    </InputLabel>
                    <TextField
                      id="contact-phone-input"
                      name="contact_phone"
                      value={form.contact_phone}
                      onChange={handleChange}
                    />
                    <FormHelperText
                      id="contact-phone-input-error-text"
                      sx={{ color: "red" }}
                    >
                      {errors.contact_phone && errors.contact_phone[0]}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                spacing={4}
              >
                <Grid xs={2}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => navigate("/customers")}
                  >
                    {t("cancel")}
                  </Button>
                </Grid>
                <Grid xs={2}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={saveCustomer}
                    disabled={loading}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Backdrop
            sx={{ open: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      ) : (
        <InputLabel>{t("loading")}</InputLabel>
      )}
      <br />
    </>
  );
};

export default WithMessages(CustomerCreatePage);
