import { useState } from "react";
import {
  Button,
  CssBaseline,
  Paper,
  Box,
  Grid,
  createTheme,
  ThemeProvider,
  Alert,
  IconButton,
  InputAdornment
} from "@mui/material";
import TextFIeld from "../../components/InputTextField";
import axios from "axios";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import dotenv from "react-dotenv";
import { useLocation } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";

const headers = {
  headers: { "Content-Type": "application/json", Accept: "application/json" },
};

const theme = createTheme();

export default function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const from = location.state?.from || "/health-dashboard";
  const { login } = useAuth();
  const [form, setForm] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = () => {
    setLoading(true);
    setError();
    let userRoles = [];

    axios
      .post(dotenv.API_URL + "/auth/login", form, headers)
      .then((response) => {
        const user = response.data.user;
        userRoles = user?.roles ?? [];
        login(response.data, from);
        const isDoctor = userRoles.some((role) => role.name === "doctor");
        if (isDoctor) {
          navigate("/health-dashboard");
        } else {
          navigate("/profile");
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          const { email, password } = error.response.data.errors;
          setError({
            email: email ? email[0] : "",
            password: password ? password[0] : "",
            message: error?.response?.data?.message ?? t("server_not_respond"),
          });
        } else {
          setError({
            message: error?.response?.data?.message ?? t("server_not_respond"),
          });
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const styles = {
    title: {
      color: "#3657D9",
      fontFamily: "Poppins",
      fontSize: "22px",
      fontWeight: "700",
      lineHeight: "26px",
    },
    text: {
      color: "#575565",
      fontSize: "16px",
      whiteSpace: "pre-line",
    },
    container: {
      minWidth: "200px",
      maxWidth: "386px",
      my: 20,
      mx: 20,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    button: {
      mt: 3,
      mb: 2,
      py: "12px",
      px: "26px",
      bgcolor: "#3657D9",
      borderRadius: 4,
      textTransform: "capitalize",
      fontSize: "17px",
      fontFamily: "Poppins",
    },
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box sx={styles.container}>
            <img src="logo.png" alt="logo" width={320} />
            {loading ? (
              <p>{t("starting_session")}</p>
            ) : (
              <Box sx={{ mt: "123px" }}>
                <form onSubmit={handleLogin}>
                  <Box sx={[styles.title, { mb: "20px" }]}>
                    {t("start_session")}
                  </Box>
                  <TextFIeld
                    required
                    fullWidth
                    id="email"
                    label={t("email")}
                    name="email"
                    autoComplete="on"
                    autoFocus
                    helperText={error?.errors?.email?.reduce(
                      (acc, cur) => acc + " " + cur,
                      ""
                    )}
                    value={form.email}
                    onChange={(e) =>
                      setForm({ ...form, email: e.target.value })
                    }
                  />
                  <TextFIeld
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t("password")}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="on"
                    color={error?.password ? "error" : "primary"}
                    variant="outlined"
                    helperText={error?.errors?.password?.reduce(
                      (acc, cur) => acc + " " + cur,
                      ""
                    )}
                    value={form.password}
                    onChange={(e) =>
                      setForm(
                        { ...form, password: e.target.value },
                        setPassword(e.target.value)
                      )
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handlePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {error ? (
                    <Alert severity="error" sx={{ mt: 3 }}>
                      {error.email && <p>{error.email}</p>}
                      {error.password && <p>{error.password}</p>}
                      {!error.email && !error.password && (
                        <p>{error.message}</p>
                      )}
                    </Alert>
                  ) : (
                    ""
                  )}
                  <div
                    onClick={() => navigate("/forgot-password")}
                    style={{
                      fontFamily: "Poppins",
                      color: "#3657D9",
                      fontSize: "12px",
                      fontWeight: 500,
                      cursor: "pointer",
                      mt: "20px",
                    }}
                    align="right"
                  >
                    {t("forgot_password")}
                  </div>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={styles.button}
                    onClick={handleLogin}
                  >
                    {t("get_into")}
                  </Button>
                </form>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(valores.png)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </ThemeProvider>
  );
}
