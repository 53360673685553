import "./App.css";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./pages/login/LoginPage";
import RegisterPage from "./pages/register/RegisterPage";
import UnsubscribePage from "./pages/register/UnsubscribePage";
import Layout from "./components/Layout";
import { ProtectedRoute } from "./components/ProtectedRoute";
import InstitutionsIndexPage from "./pages/institutions/InstitutionsIndexPage";
import InstitutionsCreatePage from "./pages/institutions/InstitutionsCreatePage";
import MedicalGroupsIndexPage from "./pages/medical-groups/MedicalGroupsIndexPage";
import MedicalGroupsCreatePage from "./pages/medical-groups/MedicalGroupsCreatePage";
import MedicalGroupsUpdatePage from "./pages/medical-groups/MedicalGroupsUpdatePage";

import AdminsCreatePage from "./pages/admin-institution/AdminsCreatePage";
import AdminsIndexPage from "./pages/admin-institution/AdminsIndexPage";
import AdminsEditPage from "./pages/admin-institution/AdminsEditPage";

import PatientsIndexPage from "./pages/patients/PatientsIndexPage";
import PatientsCreatePage from "./pages/patients/PatientsCreatePage";
import SpecificPatientIndexPage from "./pages/patients/SpecificPatientIndexPage";

import CustomerIndexPage from "./pages/customer/CustomerIndexPage";
import CustomerCreatePage from "./pages/customer/CustomerCreatePage";
import CustomerUpdatePage from "./pages/customer/CustomerUpdatePage";

import AdminCustomerIndexPage from "./pages/admin-customer/AdminCustomerIndexPage";
import AdminsCustomerCreatePage from "./pages/admin-customer/AdminCustomerCreatePage";
import AdminCustomerEditPage from "./pages/admin-customer/AdminCustomerEditPage";

import SurveysIndexPage from "./pages/surveys/SurveysIndexPage";

import ProfilePage from "./pages/profile/ProfilePage";

import EnrollmentsPage from "./pages/enrollments/EnrollmentsPage";

import NoMatch from "./pages/nomatch/NoMatch";
import ChatIndexPage from "./pages/chat/ChatIndexPage";
import CustomizeParametersPatientPage from "./pages/patients/CustomizeParametersPatientPage";
import HealthDashboardIndexPage from "./pages/health-dashboard/HealthDashboardIndexPage";
import AlertsIndexPage from "./pages/alerts/AlertsIndexPage";
import SpecificAlertPage from "./pages/alerts/SpecificAlertPage";
import PatientSurveysPage from "./pages/patients/surveys/PatientSurveysPage";
import SpecificSurveyTemplatePage from "./pages/surveys/SpecificSurveyTemplatePage";
import SurveyAnswersPage from "./pages/patients/surveys/SurveyAnswersPage";
import CustomizeDefaultParametersPage from "./pages/default-parameters/CustomizeDefaultParametersPage";
import InstitutionCustomizeParams from "./pages/institutions/InstitutionCustomizeParams";
import MedicalGroupsCustomizeParams from "./pages/medical-groups/MedicalGroupsCustomizeParams";
import PatientAlertsIndexPage from "./pages/patients/PatientAlertsIndexPage";
import SpecificSurveyPatientPage from "./pages/surveys/SpecificSurveyPatientPage";
import CustomizeSurveysIndexPage from "./pages/surveys/customize-surveys/CustomizeSurveysIndexPage";
import SurveyGlossaryPage from "./pages/surveys/SurveyGlossaryPage";

import CustomizeMultipleSurveysPage from "./pages/surveys/customize-surveys/CustomizeMultipleSurveysPage";
import CustomizeSpecificSurveyIndexPage from "./pages/surveys/customize-surveys/CustomizeSpecificSurveyIndexPage";
import CustomizeSpecificSurveyPage from "./pages/surveys/customize-surveys/CustomizeSpecificSurveyPage";
import AssignSurveyPage from "./pages/surveys/assign-surveys/AssignSurveyPage";
import { useState } from "react";
import ModifyMedicalInformationICC from "./pages/patients/medical-information/ModifyMedicalInformationICC";
import ModifyMedicalInformationHTA from "./pages/patients/medical-information/ModifyMedicalInformationHTA";
import PatientProfilePage from "./pages/patients/profile/PatientProfilePage";
import DeleteAccountPage from "./pages/delete-account/DeleteAccountPage";
import ImprovementIdeasCreatePage from "./pages/improvement-ideas/ImprovementIdeasCreatePage";
import ImprovementIdeasIndexPage from "./pages/improvement-ideas/ImprovementIdeasIndexPage";
import ForgotPasswordPage from "./pages/login/ForgotPasswordPage";
import ICCHealthDashboardGlossaryPage from "./pages/health-dashboard/ICCHealthDashboardGlossaryPage";
import PatientReportsIndexPage from "./pages/patients/reports/PatientReportsIndexPage";
import GeneralReportPage from "./pages/reports/GeneralReportPage";
import "./i18n";
import StatisticsDashboardPage from "./pages/statistics-dashboard/StatisticsDashboardPage";
import { useTranslation } from "react-i18next";
import HTAHealthDashboardGlossaryPageProtocol from "./pages/health-dashboard/HTAHealthDashboardGlossaryPageProtocol";
import HTAHealthDashboardGlossaryPageMaintenance from "./pages/health-dashboard/HTAHealthDashboardGlosaryPageMaintenance";

const App = () => {
  const [firstTitles, setFirstTitles] = useState([]);
  const [secondTitle, setSecondTitle] = useState("");
  const { t } = useTranslation();
  const setTitle = (firstTitles, secondTitle = "") => {
    setFirstTitles(firstTitles);
    setSecondTitle(secondTitle);
  };

  return (
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout firstTitles={[]} secondTitle={t("profile")}>
                <ProfilePage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/institutions"
          element={
            <ProtectedRoute>
              <Layout firstTitles={[]} secondTitle={t("institutions")}>
                <InstitutionsIndexPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/institutions/add"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={["Instituciones"]}
                secondTitle={t("enrollment")}
                goBack="/institutions"
              >
                <InstitutionsCreatePage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/institutions/:id/params"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={firstTitles}
                secondTitle={t("customize_parameters")}
                goBack={-1}
              >
                <InstitutionCustomizeParams setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/institutions/:id/admins"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={firstTitles}
                secondTitle={t("administrativos")}
                goBack={-1}
              >
                <AdminsIndexPage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/institutions/:id/admins/add"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={firstTitles}
                secondTitle={t("enrollment")}
                goBack={-1}
              >
                <AdminsCreatePage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/institutions/:id/admins/:adminId/edit"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={firstTitles}
                secondTitle={t("edit")}
                goBack={-1}
              >
                <AdminsEditPage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/patients"
          element={
            <ProtectedRoute>
              <Layout firstTitles={[]} secondTitle="Pacientes">
                <PatientsIndexPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/patients/add"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={["Pacientes"]}
                secondTitle={t("enrollment_patient")}
                goBack="/patients"
              >
                <PatientsCreatePage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/patients/:id"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={firstTitles}
                secondTitle={secondTitle}
                goBack={-1}
              >
                <SpecificPatientIndexPage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/patients/:id/profile"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={firstTitles}
                secondTitle={secondTitle}
                goBack={-1}
              >
                <PatientProfilePage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/patients/:id/customize-parameters"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={firstTitles}
                secondTitle={t("customize_parameters")}
                goBack={-1}
              >
                <CustomizeParametersPatientPage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/patients/:id/medical-information-icc"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={firstTitles}
                secondTitle={t("modify_medical_info")}
                goBack={-1}
              >
                <ModifyMedicalInformationICC setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/patients/:id/medical-information-hta"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={firstTitles}
                secondTitle={t("modify_medical_info")}
                goBack={-1}
              >
                <ModifyMedicalInformationHTA setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/patients/:id/alerts"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={firstTitles}
                secondTitle={t("alarms")}
                goBack={-1}
              >
                <PatientAlertsIndexPage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/patients/:id/surveys"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={firstTitles}
                secondTitle={t("surveys")}
                goBack={-1}
              >
                <PatientSurveysPage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/patients/:id/surveys/:surveyId"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={firstTitles}
                secondTitle={t("answers")}
                goBack={-1}
              >
                <SurveyAnswersPage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/patients/:id/reports"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={firstTitles}
                secondTitle={t("reports")}
                goBack={-1}
              >
                <PatientReportsIndexPage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/customers"
          element={
            <ProtectedRoute>
              <Layout firstTitles={[]} secondTitle="Agrupadores">
                <CustomerIndexPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/customers/add"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={["Agrupadores"]}
                secondTitle={t("enrollment")}
                goBack="/customers"
              >
                <CustomerCreatePage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/customers/:id"
          element={
            <ProtectedRoute>
              <Layout firstTitles={[]} secondTitle="Editar" goBack="/customers">
                <CustomerUpdatePage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/customers/:id/admins"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={firstTitles}
                secondTitle={t("administrativos")}
                goBack={-1}
              >
                <AdminCustomerIndexPage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/customers/:id/admins/add"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={firstTitles}
                secondTitle={t("enrollment")}
                goBack={-1}
              >
                <AdminsCustomerCreatePage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/customers/:id/admins/:adminId/edit"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={firstTitles}
                secondTitle={t("edit")}
                goBack={-1}
              >
                <AdminCustomerEditPage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/health-dashboard"
          element={
            <ProtectedRoute>
              <Layout firstTitles={[]} secondTitle="Tablero de Salud">
                <HealthDashboardIndexPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/health-dashboard/glossaryHTA"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={["protocol_table"]}
                secondTitle={t("glosary")}
                goBack="/health-dashboard"
              >
                <HTAHealthDashboardGlossaryPageProtocol />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/health-dashboard/glossaryHTA-maintenance"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={["maitenance_table"]}
                secondTitle={t("glosary")}
                goBack="/health-dashboard"
              >
                <HTAHealthDashboardGlossaryPageMaintenance />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/health-dashboard/glossaryICC"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={["Tablero de Salud"]}
                secondTitle={t("glosary")}
                goBack="/health-dashboard"
              >
                <ICCHealthDashboardGlossaryPage />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/medical-groups"
          element={
            <ProtectedRoute>
              <Layout firstTitles={[]} secondTitle={t("medical_groups")}>
                <MedicalGroupsIndexPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/medical-groups/add"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={["Equipos médicos"]}
                secondTitle={t("enrollment")}
                goBack="/medical-groups"
              >
                <MedicalGroupsCreatePage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/medical-groups/:id"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={firstTitles}
                secondTitle={t("edit")}
                goBack="/medical-groups"
              >
                <MedicalGroupsUpdatePage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/medical-groups/:id/params"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={firstTitles}
                secondTitle={t("customize_parameters")}
                goBack={-1}
              >
                <MedicalGroupsCustomizeParams setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/medical-groups/enrollments"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={[]}
                secondTitle={t("medical_group_enrollments")}
                goBack="/medical-groups"
              >
                <EnrollmentsPage />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/alerts"
          element={
            <ProtectedRoute>
              <Layout firstTitles={[]} secondTitle={t("alarms")}>
                <AlertsIndexPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/alerts/:id"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={["Alarmas"]}
                secondTitle={secondTitle}
                goBack={-1}
              >
                <SpecificAlertPage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/surveys"
          element={
            <ProtectedRoute>
              <Layout firstTitles={[]} secondTitle={t("scoreboard")}>
                <SurveysIndexPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/surveys/:id"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={["Tablero Encuestas"]}
                secondTitle={secondTitle}
                goBack={-1}
              >
                <SpecificSurveyTemplatePage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/surveys/customize-surveys"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={["Tablero Encuestas"]}
                secondTitle={t("parameterize")}
                goBack={-1}
              >
                <CustomizeSurveysIndexPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/surveys/customize-surveys/:id"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={["Tablero Encuestas", "Parametrizar"]}
                secondTitle={secondTitle}
                goBack={-1}
              >
                <CustomizeSpecificSurveyIndexPage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/surveys/customize-surveys/:id/:configurationId"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={firstTitles}
                secondTitle={t("add")}
                goBack={-1}
              >
                <CustomizeSpecificSurveyPage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/surveys/customize-surveys/multiple"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={["Tablero Encuestas", "Parametrizar"]}
                secondTitle={t("config_surveys")}
                goBack={-1}
              >
                <CustomizeMultipleSurveysPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/surveys/glossary"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={["Tablero Encuestas"]}
                secondTitle={t("glosary")}
                goBack={-1}
              >
                <SurveyGlossaryPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/surveys/patient/:id"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={["Tablero Encuestas", "Ver Encuestas"]}
                secondTitle={secondTitle}
                goBack="/surveys"
              >
                <SpecificSurveyPatientPage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/surveys/send/:id"
          element={
            <ProtectedRoute>
              <Layout
                firstTitles={["Tablero Encuestas", "Enviar"]}
                secondTitle={secondTitle}
                goBack={-1}
              >
                <AssignSurveyPage setTitle={setTitle} />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/statistics-dashboard"
          element={
            <ProtectedRoute>
              <Layout firstTitles={[]} secondTitle={t("statistics_board")}>
                <StatisticsDashboardPage />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/improvement-ideas"
          element={
            <ProtectedRoute>
              <Layout firstTitles={[]} secondTitle={t("better_reports")}>
                <ImprovementIdeasCreatePage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/view-improvement-ideas"
          element={
            <ProtectedRoute>
              <Layout firstTitles={[]} secondTitle={t("better_reports")}>
                <ImprovementIdeasIndexPage />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Layout firstTitles={[]} secondTitle={t("profile")}>
                <ProfilePage />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/chat"
          element={
            <ProtectedRoute>
              <Layout firstTitles={[]} secondTitle={t("chat")}>
                <ChatIndexPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/chat/:id"
          element={
            <ProtectedRoute>
              <Layout firstTitles={[]} secondTitle={t("chat")}>
                <ChatIndexPage />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/params"
          element={
            <ProtectedRoute>
              <Layout firstTitles={[]} secondTitle={t("patology_parameter")}>
                <CustomizeDefaultParametersPage />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="*"
          element={
            <ProtectedRoute>
              <Layout firstTitles={[]} secondTitle="" goBack="/">
                <NoMatch />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route path="/delete-account" element={<DeleteAccountPage />} />

        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reports/:id" element={<GeneralReportPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/unsubscribe/:email" element={<UnsubscribePage />} />
      </Routes>
  );
};

export default App;
