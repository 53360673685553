import { Box, Button, Checkbox } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import dotenv from "react-dotenv";
import WithMessages from "../../components/WithMessages";
import { TextField } from "../../theme/vs-theme";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const DeleteAccountPage = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  let params = new URLSearchParams(window.location.search);
  const [patientId, setPatientId] = useState(null);
  const [checked, setChecked] = useState(false);
  const [form, setForm] = useState({
    name: params?.get("name"),
    lastname: params?.get("lastname"),
    email: params?.get("email"),
    password: "",
  });

  useEffect(() => {
    const manageWindowSizeChange = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", manageWindowSizeChange);
    return () => {
      window.removeEventListener("resize", manageWindowSizeChange);
    };
  }, []);

  const deleteAccount = () => {
    axios
      .post(
        new URL(
          `/institutions/v1/patients/${patientId}/delete-account`,
          dotenv.API_URL
        ).href,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then(() =>
        props.showMessage(t("account_deletion_request_been_sent"), () =>
          navigate(-1)
        )
      );
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const checkUser = () => {
    axios
      .post(
        new URL("/institutions/v1/patients/check-account", dotenv.API_URL).href,
        form,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setPatientId(response.data.patientId);
        setStep(1);
      })
      .catch((error) => {
        props.showMessage(error.response.data[0].message, () => {}, "error");
      });
  };

  const styles = {
    centered: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
    },
    background: {
      position: "absolute",
      top: 0,
      left: 0,
      width: windowWidth,
      height: windowHeight,
      backgroundImage: `url('/delete-account-background.jpg')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      opacity: 0.5,
      zIndex: -1,
    },
    container: {
      maxWidth: "90%",
      borderRadius: "20px",
      background: "#FFF",
      boxShadow: "6px 0px 66px 0px rgba(0, 0, 0, 0.25)",
      padding: "40px 50px",
    },
    text: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0.42px",
      whiteSpace: "normal",
      maxWidth: "100%",
    },
    checkboxText: {
      color: "#869AE8",
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "21px",
      letterSpacing: "0.055px",
      whiteSpace: "normal",
      maxWidth: "468px",
      minWidth: "245px",
    },
    title: {
      fontSize: "22px",
      fontWeight: 700,
      color: "#3657D9",
      lineHeight: "26px",
      letterSpacing: "0.11px",
    },
    button: {
      fontSize: "17px",
      color: "#FCFCFC",
      fontWeight: 500,
      borderRadius: "15px",
      textTransform: "none",
    },
  };

  return (
    <Box sx={[styles.centered, { height: "100vh" }]}>
      <Box sx={styles.background}></Box>
      <Box sx={styles.container}>
        <Box sx={styles.centered}>
          <img src="/logo.png" style={{ maxWidth: "50%" }} />
        </Box>
        <Box
          sx={{
            marginTop: "50px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          {step == 0 ? (
            <>
              <Box sx={styles.title}>{t("delete_acount")}</Box>
              <Box sx={[styles.text, { color: "#3657D9" }]}>
                {t("you_must_verify_continue")}
              </Box>
              <Box display="flex" gap={"8px"}>
                <Box>
                  <Box sx={[styles.text, { color: "#869AE8" }]} ml={"8px"}>
                    {t("name")}
                  </Box>
                  <TextField
                    onChange={handleChange}
                    value={form.name}
                    name="name"
                    sx={styles.text}
                  ></TextField>
                </Box>
                <Box>
                  <Box sx={[styles.text, { color: "#869AE8" }]} ml={"8px"}>
                    {t("lastname")}
                  </Box>
                  <TextField
                    onChange={handleChange}
                    value={form.lastname}
                    name="lastname"
                    sx={styles.text}
                  ></TextField>
                </Box>
              </Box>
              <Box>
                <Box sx={[styles.text, { color: "#869AE8" }]} ml={"8px"}>
                  {t("email")}
                </Box>
                <TextField
                  onChange={handleChange}
                  value={form.email}
                  name="email"
                  fullWidth
                  sx={styles.text}
                ></TextField>
              </Box>
              <Box>
                <Box sx={[styles.text, { color: "#869AE8" }]} ml={"8px"}>
                  {t("input_password")}
                </Box>
                <TextField
                  onChange={handleChange}
                  value={form.password}
                  name="password"
                  type="password"
                  fullWidth
                  sx={styles.text}
                ></TextField>
              </Box>
              <Button
                sx={[
                  styles.button,
                  { background: "#3657D9", marginTop: "16px" },
                ]}
                fullWidth
                onClick={checkUser}
              >
                {t("coninue")}
              </Button>
            </>
          ) : (
            <>
              <Box sx={styles.title}>{t("reed_important_information")}</Box>
              <Box sx={[styles.text, { color: "#3657D9", maxWidth: "498px" }]}>
                {t("if_you_delete_acount_cannot_use_service")}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  value={checked}
                  onChange={() => setChecked(!checked)}
                />
                <Box sx={styles.checkboxText}>{t("yes_i_do_delete_vs")}</Box>
              </Box>
              <Button
                sx={[
                  styles.button,
                  { background: "#D04444", marginTop: "59px" },
                ]}
                fullWidth
                endIcon={<img src="/delete_white.png" />}
                disabled={!checked}
                onClick={deleteAccount}
              >
                {t("request_delete_acount")}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithMessages(DeleteAccountPage);
